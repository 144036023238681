<!-- components/CommentSection.vue -->
<script setup>
import { ref, computed, onMounted, nextTick } from "vue";
import { Link, useForm, usePage } from "@inertiajs/vue3";
import { TransitionGroup } from "vue";
import moment from "moment";
import { SendIcon, TrashIcon } from "lucide-vue-next";
import Button from "@/Components/Button.vue";
import EmojiInput from "@/Pages/Messages/Partials/EmojiInput.vue";
import ConfirmationDialog from "@/Components/ConfirmationDialog.vue";

const props = defineProps({
    post: Object,
    initialComments: Array,
    highlightedCommentUuid: String,
    commentableType: {
        type: String,
        default: "post",
        validator: (value) => ["post", "media"].includes(value),
    },
    commentableId: {
        type: [String, Number],
        default: null,
    },
});

const emit = defineEmits(["commentsUpdated"]);
const $page = usePage();

// Liste en dur des utilisateurs pour les mentions
const users = ref([]);

// Références pour l'éditeur et l'état
const editor = ref(null);
const hasContent = ref(false);
const showMentionSuggestions = ref(false);
const mentionQuery = ref("");
const mentionStartPosition = ref(null);
const selectedMentionIndex = ref(0);

// État des commentaires
const isLoadingMore = ref(false);
const commentSent = ref(false);
const localComments = ref([...props.initialComments]);
const currentPage = ref(1);
const totalComments = ref(props.post?.comments_count || 0);
const showDeleteCommentDialog = ref(false);
const commentToDelete = ref(null);

// Helpers pour les routes
const getCommentsRoute = () => {
    return props.commentableType === "post"
        ? route("posts.comments", props.post.uuid)
        : route("media.comments.index", props.commentableId);
};

const getCommentStoreRoute = () => {
    return props.commentableType === "post"
        ? route("posts.comment", props.post.uuid)
        : route("media.comments.store", props.commentableId);
};

const getCommentDeleteRoute = (commentUuid) => {
    return props.commentableType === "post"
        ? route("posts.comments.destroy", {
              post: props.post.uuid,
              comment: commentUuid,
          })
        : route("media.comments.destroy", {
              media: props.commentableId,
              comment: commentUuid,
          });
};

// Computed properties
const hasMoreComments = computed(() => {
    if (commentSent.value) {
        return false;
    }
    return localComments.value.length < totalComments.value;
});

const sortedComments = computed(() => {
    return [...localComments.value].sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
});

const filteredUsers = computed(() => {
    if (!mentionQuery.value) return [];
    return users.value
        .filter((user) =>
            user.username
                .toLowerCase()
                .includes(mentionQuery.value.toLowerCase())
        )
        .slice(0, 5);
});

// Gestion de l'éditeur
const handleInput = () => {
    hasContent.value = editor.value.textContent.trim().length > 0;
    checkForMention();
};

const handleKeyDown = (event) => {
    if (showMentionSuggestions.value && filteredUsers.value.length > 0) {
        switch (event.key) {
            case "ArrowDown":
                event.preventDefault();
                selectedMentionIndex.value =
                    (selectedMentionIndex.value + 1) %
                    filteredUsers.value.length;
                break;
            case "ArrowUp":
                event.preventDefault();
                selectedMentionIndex.value =
                    selectedMentionIndex.value === 0
                        ? filteredUsers.value.length - 1
                        : selectedMentionIndex.value - 1;
                break;
            case "Enter":
            case "Tab":
                event.preventDefault();
                selectMention(filteredUsers.value[selectedMentionIndex.value]);
                break;
            case "Escape":
                event.preventDefault();
                showMentionSuggestions.value = false;
                break;
        }
    } else if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        submitComment();
    }
};

const checkForMention = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);

    // Vérifier si on est dans une mention
    let node = range.startContainer;
    while (node !== null && node !== editor.value) {
        if (
            node.nodeType === Node.ELEMENT_NODE &&
            node.classList.contains("mention")
        ) {
            showMentionSuggestions.value = false;
            return;
        }
        node = node.parentNode;
    }

    // Obtenir le texte avant le curseur dans le nœud actuel
    const textNode = range.startContainer;
    if (textNode.nodeType !== Node.TEXT_NODE) {
        showMentionSuggestions.value = false;
        return;
    }

    const text = textNode.textContent;
    const cursorPosition = range.startOffset;
    const textBeforeCursor = text.substring(0, cursorPosition);

    // Trouver le dernier espace avant le curseur
    const lastSpaceIndex = textBeforeCursor.lastIndexOf(" ");
    const textFromLastSpace = textBeforeCursor.substring(lastSpaceIndex + 1);

    // Vérifier si on commence une mention
    if (textFromLastSpace.startsWith("@") && textFromLastSpace.length > 1) {
        mentionQuery.value = textFromLastSpace.substring(1);
        mentionStartPosition.value = lastSpaceIndex + 1;
        showMentionSuggestions.value = true;
        if (users.value.length === 0) {
            axios.get(route("followers-mentions")).then((response) => {
                users.value = response.data.users;
            });
        }
        selectedMentionIndex.value = 0;
    } else {
        showMentionSuggestions.value = false;
    }
};

const selectMention = (user, event) => {
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    const originalRange = saveSelection();
    if (!originalRange) return;

    const mentionElement = document.createElement("a");
    mentionElement.contentEditable = "false";
    mentionElement.classList.add("mention");
    mentionElement.href = route("members.show", user.uuid);
    mentionElement.dataset.userId = user.uuid;
    mentionElement.dataset.username = user.username;
    mentionElement.textContent = `@${user.username}`;

    const textNode = originalRange.startContainer;
    if (textNode.nodeType !== Node.TEXT_NODE) return;

    const text = textNode.textContent;
    const beforeMention = text.substring(0, mentionStartPosition.value);
    const afterMention = text.substring(originalRange.startOffset);

    const afterTextNode = document.createTextNode(afterMention);
    const spaceNode = document.createTextNode(" ");

    textNode.textContent = beforeMention;
    textNode.after(mentionElement);
    mentionElement.after(spaceNode);
    spaceNode.after(afterTextNode);

    const newRange = document.createRange();
    newRange.setStart(afterTextNode, 0);
    newRange.collapse(true);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(newRange);

    editor.value.focus();
    showMentionSuggestions.value = false;
    mentionQuery.value = "";
    editor.value.dispatchEvent(new Event("input"));
};

const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        let container = range.commonAncestorContainer;

        // Vérifier si la sélection est dans l'éditeur
        while (container !== null && container !== editor.value) {
            container = container.parentNode;
        }

        // Retourner la range seulement si elle est dans l'éditeur
        if (container === editor.value) {
            return range.cloneRange();
        }
    }
    return null;
};

const restoreSelection = (range) => {
    if (range) {
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }
};

const handleKeyPress = (event) => {
    if (event.key === "@") {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;

        let node = selection.getRangeAt(0).startContainer;
        while (node !== null && node !== editor.value) {
            if (
                node.nodeType === Node.ELEMENT_NODE &&
                node.classList.contains("mention")
            ) {
                event.preventDefault();
                return;
            }
            node = node.parentNode;
        }
    }
};

const initEditor = () => {
    if (!editor.value.firstChild) {
        editor.value.appendChild(document.createTextNode(""));
    }
};

const insertEmoji = (emoji) => {
    const savedSelection = saveSelection();
    editor.value.focus();

    if (savedSelection) {
        restoreSelection(savedSelection);
    } else {
        const range = document.createRange();
        const selection = window.getSelection();

        if (!editor.value.lastChild) {
            editor.value.appendChild(document.createTextNode(""));
        }

        range.selectNodeContents(editor.value.lastChild || editor.value);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
    }

    const textNode = document.createTextNode(emoji);
    const range = window.getSelection().getRangeAt(0);
    range.insertNode(textNode);
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);

    editor.value.dispatchEvent(new Event("input"));
};

const getEditorContent = () => {
    const clonedContent = editor.value.cloneNode(true);
    clonedContent.querySelectorAll(".mention").forEach((mention) => {
        mention.textContent = `@${mention.dataset.username}`;
    });
    return clonedContent.innerHTML;
};

const formatComment = (content) => {
    return content.replace(/@\[(\d+)\]/g, (match, userId) => {
        const user = users.value.find((u) => u.uuid === userId);
        if (user) {
            return `<span class="mention">@${user.username}</span>`;
        }
        return match;
    });
};

const commentForm = useForm({
    content: "",
    processing: false,
});

const submitComment = () => {
    commentForm.processing = true;

    const content = getEditorContent();
    if (!content.trim()) return;

    axios
        .post(getCommentStoreRoute(), {
            content: content,
        })
        .then((response) => {
            const newComment = response.data.comment;
            localComments.value.push(newComment);
            totalComments.value = response.data.comments_count;
            emit("commentsUpdated", response.data.comments_count);
            commentForm.processing = false;

            editor.value.innerHTML = "";
            hasContent.value = false;
        })
        .catch((error) => {
            console.error("Error submitting comment:", error);
            commentForm.processing = false;
        });
};

const commentsSection = ref(null);

const loadMoreComments = () => {
    if (isLoadingMore.value || !hasMoreComments.value) return;

    commentsSection.value?.scrollIntoView({
        behavior: "smooth",
        block: "start",
    });

    isLoadingMore.value = true;

    const nextPage = currentPage.value + 1;
    const params = {
        page: nextPage,
    };

    if (props.highlightedCommentUuid) {
        params.comment = props.highlightedCommentUuid;
    }

    axios
        .get(getCommentsRoute(), { params })
        .then((response) => {
            const newComments = response.data.comments;
            localComments.value = [...localComments.value, ...newComments];
            currentPage.value = nextPage;
            totalComments.value = response.data.total_count;
            hasMoreComments.value = response.data.has_more;
        })
        .catch((error) => {
            console.error("Error loading comments:", error);
        })
        .finally(() => {
            isLoadingMore.value = false;
        });
};

const confirmDeleteComment = (comment) => {
    commentToDelete.value = comment;
    showDeleteCommentDialog.value = true;
};

const deleteComment = () => {
    if (!commentToDelete.value) return;

    axios
        .delete(getCommentDeleteRoute(commentToDelete.value.uuid))
        .then(() => {
            localComments.value = localComments.value.filter(
                (c) => c.uuid !== commentToDelete.value.uuid
            );
            totalComments.value--;
            emit("commentsUpdated", totalComments.value);
            showDeleteCommentDialog.value = false;
            commentToDelete.value = null;
        })
        .catch((error) => {
            console.error("Error deleting comment:", error);
            showDeleteCommentDialog.value = false;
            commentToDelete.value = null;
        });
};

const profileOrPageLink = (comment) => {
    if (comment.page) {
        return route("pages.show", comment.page.slug);
    }
    return route("members.show", comment.user.uuid);
};

const getCommentatorName = (comment) => {
    return comment.page ? comment.page.name : comment.user.username;
};

const getCommentatorAvatar = (comment) => {
    return comment.page
        ? comment.page.profile_image_url
        : comment.user.profile_photo_url;
};

onMounted(() => {
    initEditor();

    nextTick(() => {
        if (props.highlightedCommentUuid) {
            const commentElement = document.getElementById(
                `comment-${props.highlightedCommentUuid}`
            );
            if (commentElement) {
                commentElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }
    });
});
</script>

<template>
    <div class="mt-4 px-4 pb-4" ref="commentsSection">
        <ConfirmationDialog
            :show="showDeleteCommentDialog"
            @close="showDeleteCommentDialog = false"
            @confirm="deleteComment"
            title="Supprimer le commentaire"
            content="Êtes-vous sûr de vouloir supprimer ce commentaire ?"
            confirm-text="Supprimer"
        />

        <div v-if="hasMoreComments" class="text-center text-sm mt-4">
            <Button
                @click="loadMoreComments"
                variant="outline"
                :disabled="isLoadingMore"
                class="w-full transition-all duration-200 ease-in-out transform hover:scale-[1.02]"
            >
                <template v-if="isLoadingMore">
                    <svg
                        class="animate-spin h-5 w-5 mr-2 inline"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    Chargement...
                </template>
                <template v-else> Charger plus de commentaires </template>
            </Button>
        </div>

        <TransitionGroup
            name="comment-list"
            tag="div"
            class="space-y-2 overflow-y-auto"
        >
            <div
                v-for="comment in sortedComments"
                :key="comment.uuid"
                :id="'comment-' + comment.uuid"
                class="comment-item"
            >
                <div class="flex items-start space-x-2 relative">
                    <Link
                        class="w-14 h-14 rounded-full overflow-hidden"
                        :href="profileOrPageLink(comment)"
                    >
                        <img
                            :src="getCommentatorAvatar(comment)"
                            alt="avatar"
                            class="w-14 h-14 rounded-full object-cover object-center"
                        />
                    </Link>
                    <div
                        class="px-4 py-2 bg-light rounded-lg flex-1 text-sm transform transition-all duration-300"
                        :class="{
                            'bg-primary/5':
                                comment.uuid === highlightedCommentUuid,
                        }"
                    >
                        <span
                            class="flex items-center gap-2 absolute right-4 top-3"
                        >
                            <TrashIcon
                                v-if="
                                    (comment.user.uuid ===
                                        $page.props.auth.user.uuid &&
                                        $page.props.hasGoldSubscription) ||
                                    $page.props.auth.user.is_admin
                                "
                                class="w-4 h-4 cursor-pointer"
                                @click="confirmDeleteComment(comment)"
                            />
                        </span>
                        <Link
                            class="font-semibold"
                            :href="profileOrPageLink(comment)"
                        >
                            {{ getCommentatorName(comment) }}
                        </Link>
                        <div v-html="formatComment(comment.content)"></div>
                        <p class="text-xs text-gray-500">
                            {{ moment(comment.created_at).fromNow() }}
                        </p>
                    </div>
                </div>
            </div>
        </TransitionGroup>

        <form @submit.prevent="submitComment" class="flex gap-2 mt-4 pb-8">
            <div
                class="object-cover object-center bg-primary-gradient w-14 h-14 rounded-full"
            >
                <img
                    :src="
                        $page.props.page
                            ? $page.props.page.profile_image_url
                            : $page.props.auth.user.profile_photo_url
                    "
                    alt="avatar"
                    class="w-14 h-14 rounded-full object-cover object-center"
                />
            </div>
            <div class="rounded-md flex-1 flex gap-2">
                <div class="rounded-md w-full h-20 relative">
                    <div class="absolute right-2 top-2">
                        <EmojiInput @select="insertEmoji" />
                    </div>
                    <div
                        ref="editor"
                        contenteditable="true"
                        class="p-2 border border-light rounded-md h-full focus:outline-none focus:ring-0 focus:border-primary w-full overflow-y-auto"
                        @input="handleInput"
                        @keydown="handleKeyDown"
                        @keypress="handleKeyPress"
                    ></div>

                    <div
                        v-if="
                            showMentionSuggestions && filteredUsers.length > 0
                        "
                        class="absolute bottom-full left-0 w-64 bg-white border border-gray-200 rounded-md shadow-lg max-h-48 overflow-y-auto z-50 mb-1"
                    >
                        <div
                            v-for="(user, index) in filteredUsers"
                            :key="user.uuid"
                            class="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2 transition-colors duration-150"
                            :class="{
                                'bg-gray-100': index === selectedMentionIndex,
                            }"
                            @mousedown.prevent="selectMention(user, $event)"
                        >
                            <img
                                :src="user.profile_photo_url"
                                class="w-6 h-6 rounded-full"
                                :alt="user.username"
                            />
                            <span>{{ user.username }}</span>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end">
                    <div>
                        <Button
                            type="submit"
                            :disabled="!hasContent || commentForm.processing"
                            class="transition-transform duration-150 hover:scale-105"
                            gold
                        >
                            <SendIcon class="w-5 h-5" />
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style>
/* Animations pour la liste de commentaires */
.comment-list-move,
.comment-list-enter-active,
.comment-list-leave-active {
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.comment-list-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.comment-list-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

/* Assurer que les commentaires ne se chevauchent pas pendant l'animation */
.comment-list-leave-active {
    position: absolute;
}

/* Animation du fade pour les nouveaux commentaires */
.comment-item {
    animation: fadeIn 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Styles pour les mentions */
.mention {
    @apply text-primary font-semibold cursor-pointer bg-dark/10 rounded-md px-1 py-0.5;
}

.mention:hover {
    background-color: rgba(var(--primary-rgb), 0.2);
}

/* Styles pour l'éditeur */
[contenteditable="true"] {
    white-space: pre-wrap;
    min-height: 56px;
    transition: border-color 0.2s ease;
}

[contenteditable="true"]:empty:before {
    content: "Votre commentaire...";
    color: #9ca3af;
    pointer-events: none;
    position: absolute;
}

[contenteditable="true"]:focus {
    outline: none;
}

.mention-suggestion {
    transition: background-color 0.2s ease;
}

.mention-suggestion:hover {
    background-color: rgba(var(--primary-rgb), 0.05);
}
</style>
